class Emoji {
    constructor(name, description, category, style) {
      this.name = name;
      this.description = description;
      this.category = category;
      this.style = style;
    }
}
  
let bilibiliEmoji = [
    new Emoji("baiyan", "白眼", "bilibili", {"animation-duration": "1800ms", "animation-timing-function": "steps(45)", "transform": "translateY(-1408px)", "height": "1440px"}),
    new Emoji("fadai", "发呆", "bilibili", {"animation-duration": "1080ms", "animation-timing-function": "steps(27)", "transform": "translateY(-832px)", "height": "864px"}),
    new Emoji("koubi", "抠鼻", "bilibili", {"animation-duration": "1200ms", "animation-timing-function": "steps(30)", "transform": "translateY(-928px)", "height": "960px"}),
    new Emoji("qinqin", "亲亲", "bilibili", {"animation-duration": "280ms", "animation-timing-function": "steps(7)", "transform": "translateY(-192px)", "height": "224px"}),
    new Emoji("weiqu", "委屈", "bilibili", {"animation-duration": "800ms", "animation-timing-function": "steps(20)", "transform": "translateY(-608px)", "height": "640px"}),
    new Emoji("bishi", "鄙视", "bilibili", {"animation-duration": "360ms", "animation-timing-function": "steps(9)", "transform": "translateY(-256px)", "height": "288px"}),
    new Emoji("fanu", "发怒", "bilibili", {"animation-duration": "1320ms", "animation-timing-function": "steps(33)", "transform": "translateY(-1024px)", "height": "1056px"}),
    new Emoji("kun", "困", "bilibili", {"animation-duration": "1760ms", "animation-timing-function": "steps(44)", "transform": "translateY(-1376px)", "height": "1408px"}),
    new Emoji("se", "色", "bilibili", {"animation-duration": "400ms", "animation-timing-function": "steps(10)", "transform": "translateY(-288px)", "height": "320px"}),
    new Emoji("weixiao", "微笑", "bilibili", {"animation-duration": "800ms", "animation-timing-function": "steps(20)", "transform": "translateY(-608px)", "height": "640px"}),
    new Emoji("bizui", "闭嘴", "bilibili", {"animation-duration": "1240ms", "animation-timing-function": "steps(31)", "transform": "translateY(-960px)", "height": "992px"}),
    new Emoji("ganga", "尴尬", "bilibili", {"animation-duration": "1520ms", "animation-timing-function": "steps(38)", "transform": "translateY(-1184px)", "height": "1216px"}),
    new Emoji("lengmo", "冷漠", "bilibili", {"animation-duration": "40ms", "animation-timing-function": "steps(1)", "transform": "translateY(-0px)", "height": "32px"}),
    new Emoji("shengbing", "生病", "bilibili", {"animation-duration": "1400ms", "animation-timing-function": "steps(35)", "transform": "translateY(-1088px)", "height": "1120px"}),
    new Emoji("wunai", "无奈", "bilibili", {"animation-duration": "920ms", "animation-timing-function": "steps(23)", "transform": "translateY(-704px)", "height": "736px"}),
    new Emoji("chan", "馋", "bilibili", {"animation-duration": "1600ms", "animation-timing-function": "steps(40)", "transform": "translateY(-1248px)", "height": "1280px"}),
    new Emoji("guilian", "鬼脸", "bilibili", {"animation-duration": "40ms", "animation-timing-function": "steps(1)", "transform": "translateY(-0px)", "height": "32px"}),
    new Emoji("liubixue", "流鼻血", "bilibili", {"animation-duration": "1400ms", "animation-timing-function": "steps(35)", "transform": "translateY(-1088px)", "height": "1120px"}),
    new Emoji("shengqi", "生气", "bilibili", {"animation-duration": "440ms", "animation-timing-function": "steps(11)", "transform": "translateY(-320px)", "height": "352px"}),
    new Emoji("xiaoku", "笑哭", "bilibili", {"animation-duration": "600ms", "animation-timing-function": "steps(15)", "transform": "translateY(-448px)", "height": "480px"}),
    new Emoji("daku", "大哭", "bilibili", {"animation-duration": "320ms", "animation-timing-function": "steps(8)", "transform": "translateY(-224px)", "height": "256px"}),
    new Emoji("guzhang", "鼓掌", "bilibili", {"animation-duration": "680ms", "animation-timing-function": "steps(17)", "transform": "translateY(-512px)", "height": "544px"}),
    new Emoji("liuhan", "流汗", "bilibili", {"animation-duration": "1080ms", "animation-timing-function": "steps(27)", "transform": "translateY(-832px)", "height": "864px"}),
    new Emoji("shuizhao", "睡着", "bilibili", {"animation-duration": "960ms", "animation-timing-function": "steps(24)", "transform": "translateY(-736px)", "height": "768px"}),
    new Emoji("xieyanxiao", "斜眼笑", "bilibili", {"animation-duration": "320ms", "animation-timing-function": "steps(8)", "transform": "translateY(-224px)", "height": "256px"}),
    new Emoji("dalao", "大佬", "bilibili", {"animation-duration": "1320ms", "animation-timing-function": "steps(33)", "transform": "translateY(-1024px)", "height": "1056px"}),
    new Emoji("haixiu", "害羞", "bilibili", {"animation-duration": "1240ms", "animation-timing-function": "steps(31)", "transform": "translateY(-960px))", "height": "992px"}),
    new Emoji("liulei", "流泪", "bilibili", {"animation-duration": "40ms", "animation-timing-function": "steps(1)", "transform": "translateY(-0px)", "height": "32px"}),
    new Emoji("sikao", "思考", "bilibili", {"animation-duration": "1440ms", "animation-timing-function": "steps(36)", "transform": "translateY(-1120px)", "height": "1152px"}),
    new Emoji("yiwen", "疑问", "bilibili", {"animation-duration": "840ms", "animation-timing-function": "steps(21)", "transform": "translateY(-640px)", "height": "672px"}),
    new Emoji("dalian", "打脸", "bilibili", {"animation-duration": "1480ms", "animation-timing-function": "steps(37)", "transform": "translateY(-1152px)", "height": "1184px"}),
    new Emoji("heirenwenhao", "黑人问号", "bilibili", {"animation-duration": "1040ms", "animation-timing-function": "steps(26)", "transform": "translateY(-800px)", "height": "832px"}),
    new Emoji("miantian", "腼腆", "bilibili", {"animation-duration": "1120ms", "animation-timing-function": "steps(28)", "transform": "translateY(-864px)", "height": "896px"}),
    new Emoji("tiaokan", "调侃", "bilibili", {"animation-duration": "40ms", "animation-timing-function": "steps(1)", "transform": "translateY(-0px)", "height": "32px"}),
    new Emoji("yun", "晕", "bilibili", {"animation-duration": "480ms", "animation-timing-function": "steps(12)", "transform": "translateY(-352px)", "height": "384px"}),
    new Emoji("dianzan", "点赞", "bilibili", {"animation-duration": "800ms", "animation-timing-function": "steps(20)", "transform": "translateY(-608px)", "height": "640px"}),
    new Emoji("huaixiao", "坏笑", "bilibili", {"animation-duration": "1240ms", "animation-timing-function": "steps(31)", "transform": "translateY(-960px)", "height": "992px"}),
    new Emoji("mudengkoudai", "目瞪口呆", "bilibili", {"animation-duration": "40ms", "animation-timing-function": "steps(1)", "transform": "translateY(-0px)", "height": "32px"}),
    new Emoji("tiaopi", "调皮", "bilibili", {"animation-duration": "2000ms", "animation-timing-function": "steps(50)", "transform": "translateY(-1568px)", "height": "1600px"}),
    new Emoji("zaijian", "再见", "bilibili", {"animation-duration": "960ms", "animation-timing-function": "steps(24)", "transform": "translateY(-736px)", "height": "768px"}),
    new Emoji("doge", "狗头", "bilibili", {"animation-duration": "800ms", "animation-timing-function": "steps(20)", "transform": "translateY(-608px)", "height": "640px"}),
    new Emoji("jingxia", "惊吓", "bilibili", {"animation-duration": "1280ms", "animation-timing-function": "steps(32)", "transform": "translateY(-992px)", "height": "1024px"}),
    new Emoji("nanguo", "难过", "bilibili", {"animation-duration": "1120ms", "animation-timing-function": "steps(28)", "transform": "translateY(-864px)", "height": "896px"}),
    new Emoji("touxiao", "偷笑", "bilibili", {"animation-duration": "240ms", "animation-timing-function": "steps(6)", "transform": "translateY(-160px)", "height": "192px"}),
    new Emoji("zhoumei", "皱眉", "bilibili", {"animation-duration": "40ms", "animation-timing-function": "steps(1)", "transform": "translateY(-0px)", "height": "32px"}),
    new Emoji("facai", "发财", "bilibili", {"animation-duration": "1200ms", "animation-timing-function": "steps(30)", "transform": "translateY(-928px)", "height": "960px"}),
    new Emoji("keai", "可爱", "bilibili", {"animation-duration": "680ms", "animation-timing-function": "steps(17)", "transform": "translateY(-512px)", "height": "544px"}),
    new Emoji("outu", "呕吐", "bilibili", {"animation-duration": "1680ms", "animation-timing-function": "steps(42)", "transform": "translateY(-1312px)", "height": "1344px"}),
    new Emoji("tuxue", "吐血", "bilibili", {"animation-duration": "320ms", "animation-timing-function": "steps(8)", "transform": "translateY(-224px)", "height": "256px"}),
    new Emoji("zhuakuang", "抓狂", "bilibili", {"animation-duration": "760ms", "animation-timing-function": "steps(19)", "transform": "translateY(-576px)", "height": "608px"}),
]

let menheraEmoji = [
    new Emoji("(⌒▽⌒)", "(⌒▽⌒)", "menhera"),
    new Emoji("(￣▽￣)", "(￣▽￣)", "menhera"),
    new Emoji("(=・ω・=)", "(=・ω・=)", "menhera"),
    new Emoji("(｀・ω・´)", "(｀・ω・´)", "menhera"),
    new Emoji("(〜￣△￣)〜", "(〜￣△￣)〜", "menhera"),
    new Emoji("(･∀･)", "(･∀･)", "menhera"),
    new Emoji("(°∀°)ﾉ", "(°∀°)ﾉ", "menhera"),
    new Emoji("(￣3￣)", "(￣3￣)", "menhera"),
    new Emoji("╮(￣▽￣)╭", "╮(￣▽￣)╭", "menhera"),
    new Emoji("(´_ゝ｀)", "(´_ゝ｀)", "menhera"),
    new Emoji("←_←", "←_←", "menhera"),
    new Emoji("→_→", "→_→", "menhera"),
    new Emoji("(<_<)", "(<_<)", "menhera"),
    new Emoji("(>_>)", "(>_>)", "menhera"),
    new Emoji("(;¬_¬)", "(;¬_¬)", "menhera"),
    new Emoji("(▔□▔)/", "(▔□▔)/", "menhera"),
    new Emoji("(ﾟДﾟ≡ﾟдﾟ)!?", "(ﾟДﾟ≡ﾟдﾟ)!?", "menhera"),
    new Emoji("Σ(ﾟдﾟ;)", "Σ(ﾟдﾟ;)", "menhera"),
    new Emoji("Σ(￣□￣||)", "Σ(￣□￣||)", "menhera"),
    new Emoji("(’；ω；‘)", "(’；ω；‘)", "menhera"),
    new Emoji("（/TДT)/", "（/TДT)/", "menhera"),
    new Emoji("(^・ω・^ )", "(^・ω・^ )", "menhera"),
    new Emoji("(｡･ω･｡)", "(｡･ω･｡)", "menhera"),
    new Emoji("(●￣(ｴ)￣●)", "(●￣(ｴ)￣●)", "menhera"),
    new Emoji("ε=ε=(ノ≧∇≦)ノ", "ε=ε=(ノ≧∇≦)ノ", "menhera"),
    new Emoji("(’･_･‘)", "(’･_･‘)", "menhera"),
    new Emoji("(-_-#)", "(-_-#)", "menhera"),
    new Emoji("（￣へ￣）", "（￣へ￣）", "menhera"),
    new Emoji("(￣ε(#￣)Σ", "(￣ε(#￣)Σ", "menhera"),
    new Emoji("ヽ(‘Д’)ﾉ", "ヽ(‘Д’)ﾉ", "menhera"),
    new Emoji("（#-_-)┯━┯", "（#-_-)┯━┯", "menhera"),
    new Emoji("(╯°口°)╯(┴—┴", "(╯°口°)╯(┴—┴", "menhera"),
    new Emoji("←◡←", "←◡←", "menhera"),
    new Emoji("( ♥д♥)", "( ♥д♥)", "menhera"),
    new Emoji("_(:3」∠)_", "_(:3」∠)_", "menhera"),
    new Emoji("Σ>―(〃°ω°〃)♡→", "Σ>―(〃°ω°〃)♡→", "menhera"),
    new Emoji("⁄(⁄ ⁄•⁄ω⁄•⁄ ⁄)⁄", "⁄(⁄ ⁄•⁄ω⁄•⁄ ⁄)⁄", "menhera"),
    new Emoji("(╬ﾟдﾟ)▄︻┻┳═一", "(╬ﾟдﾟ)▄︻┻┳═一", "menhera"),
    new Emoji("･*･:≡(　ε:)", "･*･:≡(　ε:)", "menhera"),
    new Emoji("(笑)", "(笑)", "menhera"),
    new Emoji("(汗)", "(汗)", "menhera"),
    new Emoji("(泣)", "(泣)", "menhera"),
    new Emoji("(苦笑)", "(苦笑)", "menhera"),
]

let tiebaEmoji = [
    new Emoji("tongue", "吐舌", "tieba"),
    new Emoji("theblackline", "尴尬", "tieba"),
    new Emoji("tear", "大哭", "tieba"),
    new Emoji("surprised", "惊哭", "tieba"),
    new Emoji("surprised2", "惊讶", "tieba"),
    new Emoji("spray", "喷", "tieba"),
    new Emoji("spit", "呕吐", "tieba"),
    new Emoji("smilingeyes", "笑眼", "tieba"),
    new Emoji("shui", "睡", "tieba"),
    new Emoji("shame", "羞辱", "tieba"),
    new Emoji("se", "色", "tieba"),
    new Emoji("rmb", "钱", "tieba"),
    new Emoji("reluctantly", "勉强", "tieba"),
    new Emoji("rbq", "观望", "tieba"),
    new Emoji("niconiconit", "niconiconit", "tieba"),
    new Emoji("niconiconi", "niconiconi", "tieba"),
    new Emoji("niconiconi_t", "niconiconi-t", "tieba"),
    new Emoji("naive", "naive", "tieba"),
    new Emoji("ku", "ku", "tieba"),
    new Emoji("huaji", "huaji", "tieba"),
    new Emoji("hu", "hu", "tieba"),
    new Emoji("han", "han", "tieba"),
    new Emoji("haha", "haha", "tieba"),
    new Emoji("good", "good", "tieba"),
    new Emoji("doubt", "doubt", "tieba"),
    new Emoji("britan", "britan", "tieba"),
    new Emoji("bbd", "bbd", "tieba"),
    new Emoji("awesome", "awesome", "tieba"),
    new Emoji("anger", "anger", "tieba"),
    new Emoji("aa", "aa", "tieba"),
    new Emoji("Happy", "Happy", "tieba"),
    new Emoji("Grievance", "Grievance", "tieba"),
]

export default [...bilibiliEmoji, ...menheraEmoji, ...tiebaEmoji]